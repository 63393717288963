
/**
 * API OFFICIAL: http://test.aes-api.usb.org.br/
 * API TEST: https://aes-api.usb.org.br/
 */

//let test     = 'http://10.11.82.19:3333/'
let test     = 'http://test.aes-api.usb.org.br/'
let official = 'https://aes-api.usb.org.br/'

export const baseApiURL = process.env.NODE_ENV === 'production' ? official : test
