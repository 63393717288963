import Vue from 'vue'

export async function setDestroySessions(context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})

        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/sessao/destroy',
            data: {
                sessao_id: parameters.id
            }
        })

        if (response.data.status === 'success') {
            context.commit('setLoading', false, {root: true})
            return response.data.data
        } else {
            context.commit('setLoading', false, {root: true})
            return response
        }

    } catch (e) {
        context.commit('setLoading', false, {root: true})
        return false
    }

}
