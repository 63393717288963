<template>
  <div class="local-loading">
    <div v-if="loading" class="loadingPage d-flex justify-content-center align-items-center">
      <svg width="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-eclipse">
        <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#0097ff" transform="rotate(95.7946 50 51)">
          <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
        </path>
      </svg>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex'

export default {
  name: "Loading",
  computed: {
    ...mapState( ['loading'] )
  },

}
</script>

<style scoped>

</style>