import Vue from 'vue'

export async function setUpdate(context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})

        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/evento/update',
            data: {
                evento_id: parameters.id,
                nome: parameters.title,
                inicio: parameters.dateStart + ' ' + parameters.timeStart,
                fim: parameters.dateEnd + ' ' + parameters.timeEnd,
                centro_evento_id: parameters.eventCenterId,
                descricao: parameters.abstract,
                informacao: parameters.info,
                foto: parameters.photo
            }
        })

        if (response.data.status === 'success') {
            context.commit('setLoading', false, {root: true})
            return response.data
        } else {
            context.commit('setLoading', false, {root: true})
            return false
        }

    } catch (e) {
        context.commit('setLoading', false, {root: true})
        return false
    }

}
