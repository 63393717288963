import Vue from 'vue'

export async function setUpdateLecture(context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})

        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/palestra/update',
            data: {
                evento_id: parameters.event_id,
                sala_id: parameters.room_id,
                palestra_id: parameters.id,
                inicio: parameters.dateStart + ' ' + parameters.timeStart,
                fim: parameters.dateEnd + ' ' + parameters.endTime,
                titulo: parameters.title,
                resumo: parameters.abstract,
                video: parameters.link,
                foto: parameters.photo,
                workshop: parameters.workshop,
                tipo_palestra_id: parameters.type,
                limite_vaga: parameters.limit,
                palestrantes: JSON.stringify(parameters.peoples),
            }
        })

        context.commit('setLoading', false, {root: true})
        return response.data

    } catch (e) {
        console.log(e)
        context.commit('setLoading', false, {root: true})
        return false
    }

}
