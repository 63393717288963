import Vue from 'vue'

export async function getAll(context ) {

    try {

        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/evento/lista-centro-evento'
        })

        if (response.data.status === 'success') {
            context.commit('setLoading', false, {root: true})
            return response.data.data
        } else {
            context.commit('setShowNotificationError', {
                title: 'Oops',
                message: 'Não foi possível buscar centro de eventos',
            }, {root: true})
            return false
        }

    } catch (e) {
        context.commit('setShowNotificationError', {
            title: 'Oops',
            message: 'Não foi possível buscar centro de eventos',
        }, {root: true})
        return false
    }
}
