import Vue from 'vue'

export async function setStoreTrails(context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})
        console.log("setStoreTrails", parameters)
        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/trilha/store',
            data: {
                evento_id: parameters.event_id,
                descricao: parameters.title
            }
        })

        if (response.data.status === 'success') {
            context.commit('setLoading', false, {root: true})
            return response.data.data
        } else {
            context.commit('setLoading', false, {root: true})
            return response
        }

    } catch (e) {
        context.commit('setLoading', false, {root: true})
        return false
    }

}
