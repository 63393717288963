import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import axios from "axios";
import {baseApiURL} from "@/config/api";
import VueAxios from "vue-axios"
Vue.use( VueAxios, axios )

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/auth/:user/',
    name: 'Auth',
    component: () => import( '../views/auth/LoginByID')
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: () => import( '../views/auth/AccessDenied')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import( '../views/Home'),
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import( '../views/About.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import( '../views/events/ListEvents'),
    meta: { requiresAuth: true }
  },
  {
    path: '/event-details/:event',
    name: 'EventDetails',
    component: () => import( '../views/events/DetailsEvent'),
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  let token = localStorage.getItem('aes:auto:auth')

  if (token) {
    axios.defaults.baseURL  = baseApiURL
    axios.defaults.headers.common['Authorization'] = `bearer ${token}`
  } else {
    axios.defaults.headers.common['Authorization'] = ''
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.auth['user'].id) {
    next({
      name: 'AccessDenied'
    })
  }
  else {
    next()
  }
})

export default router
