
import Vue from 'vue'
import axios from 'axios'
import {baseApiURL} from '@/config/api'
import VueAxios from "vue-axios"

export function setUser( state, payload ) {

    if (payload) {

        let user    = payload.data.user
        let token   = payload.data.token.token

        state.user.id           = user.UsersId
        state.user.name         = user.Name
        state.user.admin        = user.Administrator === 1

        axios.defaults.baseURL  = baseApiURL
        axios.defaults.headers.common['Authorization'] = `bearer ${token}`

        localStorage.setItem('aes:auto:auth', token)

        Vue.use( VueAxios, axios )
    }
}


export function setRemoveUser( state ) {

    state.user.id           = null
    state.user.name         = null
    state.user.admin        = null

    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem('aes:auto:auth')
}