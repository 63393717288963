import Vue from 'vue'

export async function loginByID( context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})

        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/usuario/auth-usuario',
            data: { id: parameters.id }
        })

        if (response.data.status === 'success') {
            context.commit('setUser', response.data);
            context.commit('setLoading', false, {root: true})
            return true
        } else {
            context.commit('setRemoveUser')
            context.commit('setLoading', false, {root: true})
            return false
        }

    } catch (e) {
        context.commit('setRemoveUser')
        context.commit('setLoading', false, {root: true})
        return false
    }

}
