import Vue from 'vue'

export async function searchEvents(context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})

        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/evento/pesquisa',
            data: { pagina: parameters.page, pesquisa: parameters.search }
        })

        if (response.data.status === 'success') {
            context.commit('setLoading', false, {root: true})
            return response.data.data
        } else {
            context.commit('setShowNotificationError', {
                title: 'Oops',
                message: 'Não foi possível pesquisar eventos',
            }, {root: true})
            return false
        }

    } catch (e) {
        context.commit('setShowNotificationError', {
            title: 'Oops',
            message: 'Não foi possível pesquisar eventos',
        }, {root: true})
        return false
    }

}
