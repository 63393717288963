import Vue from 'vue'
import VeeValidate, {Validator} from 'vee-validate'
import pt_BR from 'vee-validate/dist/locale/pt_BR'

Validator.localize('pt_BR', pt_BR)

Vue.use(VeeValidate, {
    inject: true,
    fieldsBagName: 'veeFields',
    errorBagName: 'veeErrors',
    locale: 'pt_BR'
})