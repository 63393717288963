<template>
  <div id="app">
    <Loading/>
    <Menu/>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
import Loading from "@/components/layout/Loading";
import Menu from "@/components/layout/Menu";
import Footer from "@/components/layout/Footer";
import {mapActions} from 'vuex'


export default {
  components: {Loading, Menu, Footer},
  created() {
    this.isVerifyUser()
  },
  methods: {
    ...mapActions('auth', ['updateAuth']),

    async isVerifyUser() {
      let token = localStorage.getItem('aes:auto:auth')
      if (token) {
        let access = await this.updateAuth({token})
        if (!access) {
          await this.$router.push('AccessDenied')
        }
      }
    }
  }
}
</script>