import Vue from 'vue'
import axios from "axios";
import {baseApiURL} from "@/config/api";
import VueAxios from "vue-axios";
Vue.use( VueAxios, axios )

export async function updateAuth( context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})

        axios.defaults.baseURL  = baseApiURL
        Vue.axios.defaults.headers.common['Authorization'] = `bearer ${parameters.token}`

        const response = await  Vue.axios({
            method: 'POST',
            url: '/api/v2/usuario/update-auth'
        })

        if (response.data.status === 'success') {
            context.commit('setUser', response.data);
            context.commit('setLoading', false, {root: true})
            return true
        } else {
            context.commit('setRemoveUser')
            context.commit('setLoading', false, {root: true})
            return false
        }

    } catch (e) {
        context.commit('setRemoveUser')
        context.commit('setLoading', false, {root: true})
        return false
    }
}
