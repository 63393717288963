import Vue from 'vue'

export async function setUpdateTrails(context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})

        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/trilha/update',
            data: {
                trilha_id: parameters.id,
                descricao: parameters.title,
                palestras: JSON.stringify(parameters.checked)
            }
        })

        if (response.data.status === 'success') {
            context.commit('setLoading', false, {root: true})
            return response.data.data
        } else {
            context.commit('setLoading', false, {root: true})
            return false
        }

    } catch (e) {
        context.commit('setLoading', false, {root: true})
        return false
    }

}
