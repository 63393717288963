import Vue from 'vue'

export async function setUpdateConfig(context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})
        console.log(parameters.form)
        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/evento/update-configuracao',
            data: parameters.form
        })

        if (response.data.status === 'success') {
            context.commit('setLoading', false, {root: true})
            return response.data
        } else {
            context.commit('setLoading', false, {root: true})
            return false
        }

    } catch (e) {
        context.commit('setLoading', false, {root: true})
        return false
    }

}
