import axios from "axios";

export async function setDestroyUpload(context, paramters ) {

    try {
        context.commit('setLoading', true, {root: true})

        let base = 'api/v1/imagem/delete'
        let API_UPLOAD = process.env.NODE_ENV === 'production'
            ? 'https://servicos.usb.org.br/'
            : 'http://test-servicos.usb.org.br/'

        const response = await axios.post(
            API_UPLOAD+base,
            {
                nome_imagem: paramters.image,
                path: paramters.path
            },
        )

        context.commit('setLoading', false, {root: true})

        return response.status === 200;

    } catch (e) {
        context.commit('setLoading', false, {root: true})
        return false
    }
}
