<template>
  <div>
    <footer class="footer">
      <div class="container-fluid text-center">
        <span class="text-footer"> &copy; 2019 | União Sul Brasileira</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>