import axios from "axios";

export async function setUpload(context, form ) {

    try {
        context.commit('setLoading', true, {root: true})

        let base = 'api/v1/imagem/upload'
        let API_UPLOAD = process.env.NODE_ENV === 'production'
            ? 'https://servicos.usb.org.br/'
            : 'http://test-servicos.usb.org.br/'

        const response = await axios.post(
            API_UPLOAD+base,
            form,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )

        context.commit('setLoading', false, {root: true})
        if (response.status === 200) {
            return response.data.data.nome_imagem
        } else {
            return false
        }

    } catch (e) {
        context.commit('setLoading', false, {root: true})
        return false
    }

}
