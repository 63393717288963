import Vue from 'vue'

export async function setInscriptionWorkshop(context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})

        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/evento/abreFecha-inscricao-workshop',
            data: {
                evento_id: parameters.id,
                fechado_inscricao_workshop: parameters.option
            }
        })

        if (response.data.status === 'success') {
            context.commit('setLoading', false, {root: true})
            return response.data.data
        } else {
            context.commit('setLoading', false, {root: true})
            return false
        }

    } catch (e) {
        context.commit('setLoading', false, {root: true})
        return false
    }

}
