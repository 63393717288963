import Vue from 'vue'

export async function setStore(context, parameters ) {

    try {
        context.commit('setLoading', true, {root: true})

        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/evento/store',
            data: {
                nome: parameters.title,
                inicio: parameters.dateStart + ' ' + parameters.timeStart,
                fim: parameters.dateEnd + ' ' + parameters.timeEnd,
                entidade_id: parameters.entityId,
                centro_evento_id: parameters.eventCenterId
            }
        })

        if (response.data.status === 'success') {
            context.commit('setLoading', false, {root: true})
            return response.data
        } else {
            context.commit('setLoading', false, {root: true})
            return false
        }

    } catch (e) {
        context.commit('setLoading', false, {root: true})
        return false
    }

}
