<template>
  <div>
    <nav class="navbar sticky-top navbar-expand-lg" hidden>
      <a class="navbar-brand disabled">
        <img src="../../../public/lib/images/logo_menu.png" class="img-fluid" style="width: 40%;" alt="AES">
      </a>
      <button class="btn navbar-toggler navbar-light" type="button" data-toggle="collapse" data-target="#navbar-evento"
              aria-controls="navbar-evento" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>


      <div class="collapse navbar-collapse" id="navbar-evento">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0 menu">
          <li class="nav-item">
            <a href="#" class="nav-link pd-router-link" @click="home">
              <i class="fal fa-home"></i> Home
            </a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link pd-router-link" to="/events"><i class="fal fa-calendar-alt"></i> Evento</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link pd-router-link" to="/about"><i class="fal fa-church"></i> Sobre</router-link>
          </li>
        </ul>
      </div>
    </nav>

    <div>
      <b-navbar toggleable="lg" type="light" variant="light" class="top-top">

        <b-navbar-brand href="#">
          <img src="../../../public/lib/images/logo_menu.png" class="img-fluid" style="width: 40%;" alt="AES">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>



        <b-collapse id="nav-collapse" is-nav>

          <ul class="navbar-nav mr-auto mt-2 mt-lg-0 menu">
            <li class="nav-item">
              <a href="#" class="nav-link pd-router-link" @click="home">
                <i class="fal fa-home"></i> Home
              </a>
            </li>
            <li class="nav-item">
              <router-link class="nav-link pd-router-link" to="/events"><i class="fal fa-calendar-alt"></i> Evento</router-link>
            </li>
          </ul>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">

            <b-nav-item-dropdown text="Lang" right hidden>
              <b-dropdown-item href="#">EN</b-dropdown-item>
              <b-dropdown-item href="#">ES</b-dropdown-item>
              <b-dropdown-item href="#">RU</b-dropdown-item>
              <b-dropdown-item href="#">FA</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <em>{{ userName }} &nbsp;&nbsp; <b-avatar class="ml-2"></b-avatar></em>
              </template>
              <b-dropdown-item href="#"><i class="fal fa-user-alt"></i> Perfil</b-dropdown-item>
              <b-dropdown-item href="#" @click="logout"><i class="fal fa-sign-out"></i>&nbsp;&nbsp;Sair</b-dropdown-item>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item disabled" type="button">2.0.1</button>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

  </div>


</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "Menu",
  computed: {
    ...mapGetters('auth', ['getNameUser']),
    userName() {
      return this.getNameUser
    }
  },
  methods: {
    home() {
      let router = 'aes-new/paginas/home/home.php';
      window.location.href = process.env.NODE_ENV === 'production' ? 'https://sistemas.usb.org.br/' + router : 'http://test-sistemas.usb.org.br/' + router
    },
    logout() {
      window.localStorage.removeItem('aes:auto:auth')
      window.location.href = process.env.NODE_ENV === 'production' ? 'https://sistemas.usb.org.br' : 'http://test-sistemas.usb.org.br'
    }
  }
}
</script>

<style scoped>
  .top-top {
    z-index: 1000;
  }
  .menu-padding {
    padding-right: 1rem;
  }
</style>