import Vue from 'vue'

export async function getEntities(context ) {

    try {
        context.commit('setLoading', true, {root: true})

        const response = await Vue.axios({
            method: 'POST',
            url: '/api/v2/entidade/entidade-e-filhos'
        })

        if (response.data.status === 'success') {
            context.commit('setLoading', false, {root: true})
            return response.data.data
        } else {
            context.commit('setShowNotificationError', {
                title: 'Oops',
                message: 'Não foi possível buscar as instituições do usuário',
            }, {root: true})
            return false
        }

    } catch (e) {
        context.commit('setShowNotificationError', {
            title: 'Oops',
            message: 'Não foi possível buscar as instituições do usuário',
        }, {root: true})
        return false
    }

}
