import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist";

import auth from './modules/auth'
import user from './modules/user'
import events from './modules/events'
import centerEvents from './modules/centerEvents'
import lecture from './modules/lecture'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence(({
  storage: window.localStorage,
  modules: ['auth'],
  key: 'aes:persiste'
}))

export default new Vuex.Store({
  state: {
    loading: false,
    notification: {
      show: false,
      type: null,
      title: null,
      message: null
    }
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool
    },
    setShowNotificationSuccess(state, obj) {
      state.notification.show    = true
      state.notification.type    = 'success'
      state.notification.title   = obj.title
      state.notification.message = obj.message
      state.loading              = false
    },
    setShowNotificationError(state, obj) {
      state.notification.show    = true
      state.notification.type    = 'error'
      state.notification.title   = obj.title
      state.notification.message = obj.message
      state.loading              = false
    },
    setRemoveNotification(state) {
      state.notification.show    = false
      state.notification.type    = null
      state.notification.title   = null
      state.notification.message = null
      state.loading              = false
    }
  },
  actions: {
  },
  getters: {
    getNotifications(state) {
      return state.notification
    }
  },
  modules: {
    auth,
    user,
    events,
    centerEvents,
    lecture
  },
  plugins: [vuexLocal.plugin]
})
